import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="モーダル - コンポーネント" mdxType="SEO" />
    <PageTitle title="モーダル" enTitle="Modal" mdxType="PageTitle" />
    <p>{`Modalはページ上の操作を無効にし、ユーザーに情報を提供したり、ユーザーがタスクを完了するのを助けたりするコンポーネントです。`}</p>
    <p>{`一時的にユーザーのインタラクションを中断するため、安易に使用しないでください。`}</p>
    <h2 {...{
      "id": "パターン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BF%E3%83%BC%E3%83%B3",
        "aria-label": "パターン permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`パターン`}</h2>
    <h3 {...{
      "id": "full-modal全画面モーダル",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#full-modal%E5%85%A8%E7%94%BB%E9%9D%A2%E3%83%A2%E3%83%BC%E3%83%80%E3%83%AB",
        "aria-label": "full modal全画面モーダル permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Full Modal（全画面モーダル）`}</h3>
    <h4>{`特長`}</h4>
    <ul>
      <li parentName="ul">{`コンテンツに集中させることができる`}</li>
      <li parentName="ul">{`メインタスクから切り離された新しいタスクを発生させることができる`}</li>
      <li parentName="ul">{`多くの情報を見せることができる`}</li>
    </ul>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/20afe18114680a1ef5e08c5859e5764c/21b4d/modal-full.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/20afe18114680a1ef5e08c5859e5764c/2aaaf/modal-full.webp 160w", "/static/20afe18114680a1ef5e08c5859e5764c/85e47/modal-full.webp 320w", "/static/20afe18114680a1ef5e08c5859e5764c/75198/modal-full.webp 640w", "/static/20afe18114680a1ef5e08c5859e5764c/691bc/modal-full.webp 960w", "/static/20afe18114680a1ef5e08c5859e5764c/223e5/modal-full.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/20afe18114680a1ef5e08c5859e5764c/69538/modal-full.png 160w", "/static/20afe18114680a1ef5e08c5859e5764c/72799/modal-full.png 320w", "/static/20afe18114680a1ef5e08c5859e5764c/6af66/modal-full.png 640w", "/static/20afe18114680a1ef5e08c5859e5764c/d9199/modal-full.png 960w", "/static/20afe18114680a1ef5e08c5859e5764c/21b4d/modal-full.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/20afe18114680a1ef5e08c5859e5764c/6af66/modal-full.png",
              "alt": "Full Modalの例",
              "title": "Full Modalの例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/21b4d/modal-full-push.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/2aaaf/modal-full-push.webp 160w", "/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/85e47/modal-full-push.webp 320w", "/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/75198/modal-full-push.webp 640w", "/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/691bc/modal-full-push.webp 960w", "/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/223e5/modal-full-push.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/69538/modal-full-push.png 160w", "/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/72799/modal-full-push.png 320w", "/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/6af66/modal-full-push.png 640w", "/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/d9199/modal-full-push.png 960w", "/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/21b4d/modal-full-push.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e56ff2dcf1d3b8d0fbd655c3fa060bc3/6af66/modal-full-push.png",
              "alt": "Full ModalとPushの例",
              "title": "Full ModalとPushの例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4e6ef1b687588730477b53f853704130/21b4d/modal-full-semi50.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4e6ef1b687588730477b53f853704130/2aaaf/modal-full-semi50.webp 160w", "/static/4e6ef1b687588730477b53f853704130/85e47/modal-full-semi50.webp 320w", "/static/4e6ef1b687588730477b53f853704130/75198/modal-full-semi50.webp 640w", "/static/4e6ef1b687588730477b53f853704130/691bc/modal-full-semi50.webp 960w", "/static/4e6ef1b687588730477b53f853704130/223e5/modal-full-semi50.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4e6ef1b687588730477b53f853704130/69538/modal-full-semi50.png 160w", "/static/4e6ef1b687588730477b53f853704130/72799/modal-full-semi50.png 320w", "/static/4e6ef1b687588730477b53f853704130/6af66/modal-full-semi50.png 640w", "/static/4e6ef1b687588730477b53f853704130/d9199/modal-full-semi50.png 960w", "/static/4e6ef1b687588730477b53f853704130/21b4d/modal-full-semi50.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4e6ef1b687588730477b53f853704130/6af66/modal-full-semi50.png",
              "alt": "Full ModalとSemi Modal 50%の例",
              "title": "Full ModalとSemi Modal 50%の例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "page-sheetiosモーダル",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#page-sheetios%E3%83%A2%E3%83%BC%E3%83%80%E3%83%AB",
        "aria-label": "page sheetiosモーダル permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Page Sheet（iOSモーダル）`}</h3>
    <h4>{`特長`}</h4>
    <ul>
      <li parentName="ul">{`コンテンツに比較的集中させることができる`}</li>
      <li parentName="ul">{`インタラクションコストが比較的低い`}</li>
      <li parentName="ul">{`メインタスクから切り離された新しいタスクを発生させてもよい`}</li>
      <li parentName="ul">{`多くの情報を見せることができる`}</li>
      <li parentName="ul">{`Modalを幾重にもスタックできる`}</li>
      <li parentName="ul">{`Modalがスタックされているのが視覚的に理解出来る`}</li>
    </ul>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/407c27e94c3edfff14140758e06132cb/21b4d/modal-pagesheet.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/407c27e94c3edfff14140758e06132cb/2aaaf/modal-pagesheet.webp 160w", "/static/407c27e94c3edfff14140758e06132cb/85e47/modal-pagesheet.webp 320w", "/static/407c27e94c3edfff14140758e06132cb/75198/modal-pagesheet.webp 640w", "/static/407c27e94c3edfff14140758e06132cb/691bc/modal-pagesheet.webp 960w", "/static/407c27e94c3edfff14140758e06132cb/223e5/modal-pagesheet.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/407c27e94c3edfff14140758e06132cb/69538/modal-pagesheet.png 160w", "/static/407c27e94c3edfff14140758e06132cb/72799/modal-pagesheet.png 320w", "/static/407c27e94c3edfff14140758e06132cb/6af66/modal-pagesheet.png 640w", "/static/407c27e94c3edfff14140758e06132cb/d9199/modal-pagesheet.png 960w", "/static/407c27e94c3edfff14140758e06132cb/21b4d/modal-pagesheet.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/407c27e94c3edfff14140758e06132cb/6af66/modal-pagesheet.png",
              "alt": "Page Sheetの例",
              "title": "Page Sheetの例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/120fb47484a6794be9fc9572f9bff58f/21b4d/modal-pagesheet-pagesheet.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/120fb47484a6794be9fc9572f9bff58f/2aaaf/modal-pagesheet-pagesheet.webp 160w", "/static/120fb47484a6794be9fc9572f9bff58f/85e47/modal-pagesheet-pagesheet.webp 320w", "/static/120fb47484a6794be9fc9572f9bff58f/75198/modal-pagesheet-pagesheet.webp 640w", "/static/120fb47484a6794be9fc9572f9bff58f/691bc/modal-pagesheet-pagesheet.webp 960w", "/static/120fb47484a6794be9fc9572f9bff58f/223e5/modal-pagesheet-pagesheet.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/120fb47484a6794be9fc9572f9bff58f/69538/modal-pagesheet-pagesheet.png 160w", "/static/120fb47484a6794be9fc9572f9bff58f/72799/modal-pagesheet-pagesheet.png 320w", "/static/120fb47484a6794be9fc9572f9bff58f/6af66/modal-pagesheet-pagesheet.png 640w", "/static/120fb47484a6794be9fc9572f9bff58f/d9199/modal-pagesheet-pagesheet.png 960w", "/static/120fb47484a6794be9fc9572f9bff58f/21b4d/modal-pagesheet-pagesheet.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/120fb47484a6794be9fc9572f9bff58f/6af66/modal-pagesheet-pagesheet.png",
              "alt": "Page SheetとPage Sheetの例",
              "title": "Page SheetとPage Sheetの例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0010d1caa9018a582fa1695f067d4113/21b4d/modal-pagesheet-push.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "126.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0010d1caa9018a582fa1695f067d4113/2aaaf/modal-pagesheet-push.webp 160w", "/static/0010d1caa9018a582fa1695f067d4113/85e47/modal-pagesheet-push.webp 320w", "/static/0010d1caa9018a582fa1695f067d4113/75198/modal-pagesheet-push.webp 640w", "/static/0010d1caa9018a582fa1695f067d4113/691bc/modal-pagesheet-push.webp 960w", "/static/0010d1caa9018a582fa1695f067d4113/223e5/modal-pagesheet-push.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0010d1caa9018a582fa1695f067d4113/69538/modal-pagesheet-push.png 160w", "/static/0010d1caa9018a582fa1695f067d4113/72799/modal-pagesheet-push.png 320w", "/static/0010d1caa9018a582fa1695f067d4113/6af66/modal-pagesheet-push.png 640w", "/static/0010d1caa9018a582fa1695f067d4113/d9199/modal-pagesheet-push.png 960w", "/static/0010d1caa9018a582fa1695f067d4113/21b4d/modal-pagesheet-push.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0010d1caa9018a582fa1695f067d4113/6af66/modal-pagesheet-push.png",
              "alt": "Page SheetとPushの例",
              "title": "Page SheetとPushの例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "semi-modal-50半モーダル",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#semi-modal-50%E5%8D%8A%E3%83%A2%E3%83%BC%E3%83%80%E3%83%AB",
        "aria-label": "semi modal 50半モーダル permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Semi Modal 50%（半モーダル）`}</h3>
    <h4>{`特長`}</h4>
    <ul>
      <li parentName="ul">{`インタラクションコストが低い`}</li>
      <li parentName="ul">{`ユーザーのタスクをあまり妨げない`}</li>
      <li parentName="ul">{`前画面のコンテキストを継承できる`}</li>
    </ul>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/71ef0882d1f4c61992875431620e9cdf/21b4d/modal-semi50.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/71ef0882d1f4c61992875431620e9cdf/2aaaf/modal-semi50.webp 160w", "/static/71ef0882d1f4c61992875431620e9cdf/85e47/modal-semi50.webp 320w", "/static/71ef0882d1f4c61992875431620e9cdf/75198/modal-semi50.webp 640w", "/static/71ef0882d1f4c61992875431620e9cdf/691bc/modal-semi50.webp 960w", "/static/71ef0882d1f4c61992875431620e9cdf/223e5/modal-semi50.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/71ef0882d1f4c61992875431620e9cdf/69538/modal-semi50.png 160w", "/static/71ef0882d1f4c61992875431620e9cdf/72799/modal-semi50.png 320w", "/static/71ef0882d1f4c61992875431620e9cdf/6af66/modal-semi50.png 640w", "/static/71ef0882d1f4c61992875431620e9cdf/d9199/modal-semi50.png 960w", "/static/71ef0882d1f4c61992875431620e9cdf/21b4d/modal-semi50.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/71ef0882d1f4c61992875431620e9cdf/6af66/modal-semi50.png",
              "alt": "Semi Modal 50%の例",
              "title": "Semi Modal 50%の例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/29c5b60b717e6d65d2546bfff07da5c7/21b4d/modal-semi50-full.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/29c5b60b717e6d65d2546bfff07da5c7/2aaaf/modal-semi50-full.webp 160w", "/static/29c5b60b717e6d65d2546bfff07da5c7/85e47/modal-semi50-full.webp 320w", "/static/29c5b60b717e6d65d2546bfff07da5c7/75198/modal-semi50-full.webp 640w", "/static/29c5b60b717e6d65d2546bfff07da5c7/691bc/modal-semi50-full.webp 960w", "/static/29c5b60b717e6d65d2546bfff07da5c7/223e5/modal-semi50-full.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/29c5b60b717e6d65d2546bfff07da5c7/69538/modal-semi50-full.png 160w", "/static/29c5b60b717e6d65d2546bfff07da5c7/72799/modal-semi50-full.png 320w", "/static/29c5b60b717e6d65d2546bfff07da5c7/6af66/modal-semi50-full.png 640w", "/static/29c5b60b717e6d65d2546bfff07da5c7/d9199/modal-semi50-full.png 960w", "/static/29c5b60b717e6d65d2546bfff07da5c7/21b4d/modal-semi50-full.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/29c5b60b717e6d65d2546bfff07da5c7/6af66/modal-semi50-full.png",
              "alt": "Semi Modal 50%とFull Modalの例",
              "title": "Semi Modal 50%とFull Modalの例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f434f862f0e6b8b96a643a503e89e93d/21b4d/modal-semi50-half.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "96.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f434f862f0e6b8b96a643a503e89e93d/2aaaf/modal-semi50-half.webp 160w", "/static/f434f862f0e6b8b96a643a503e89e93d/85e47/modal-semi50-half.webp 320w", "/static/f434f862f0e6b8b96a643a503e89e93d/75198/modal-semi50-half.webp 640w", "/static/f434f862f0e6b8b96a643a503e89e93d/691bc/modal-semi50-half.webp 960w", "/static/f434f862f0e6b8b96a643a503e89e93d/223e5/modal-semi50-half.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f434f862f0e6b8b96a643a503e89e93d/69538/modal-semi50-half.png 160w", "/static/f434f862f0e6b8b96a643a503e89e93d/72799/modal-semi50-half.png 320w", "/static/f434f862f0e6b8b96a643a503e89e93d/6af66/modal-semi50-half.png 640w", "/static/f434f862f0e6b8b96a643a503e89e93d/d9199/modal-semi50-half.png 960w", "/static/f434f862f0e6b8b96a643a503e89e93d/21b4d/modal-semi50-half.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f434f862f0e6b8b96a643a503e89e93d/6af66/modal-semi50-half.png",
              "alt": "Semi Modal 50%とSemi Modal 50%の例",
              "title": "Semi Modal 50%とSemi Modal 50%の例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "semi-modal-909割モーダル",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#semi-modal-909%E5%89%B2%E3%83%A2%E3%83%BC%E3%83%80%E3%83%AB",
        "aria-label": "semi modal 909割モーダル permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Semi Modal 90%（9割モーダル）`}</h3>
    <h4>{`特長`}</h4>
    <ul>
      <li parentName="ul">{`コンテンツに比較的集中させることができる`}</li>
      <li parentName="ul">{`インタラクションコストが比較的低い`}</li>
      <li parentName="ul">{`メインタスクから切り離された新しいタスクを発生させてもよい`}</li>
      <li parentName="ul">{`多くの情報を見せることができる`}</li>
    </ul>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/293ec49cc616edac7648784fd469d329/21b4d/modal-semi90.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/293ec49cc616edac7648784fd469d329/2aaaf/modal-semi90.webp 160w", "/static/293ec49cc616edac7648784fd469d329/85e47/modal-semi90.webp 320w", "/static/293ec49cc616edac7648784fd469d329/75198/modal-semi90.webp 640w", "/static/293ec49cc616edac7648784fd469d329/691bc/modal-semi90.webp 960w", "/static/293ec49cc616edac7648784fd469d329/223e5/modal-semi90.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/293ec49cc616edac7648784fd469d329/69538/modal-semi90.png 160w", "/static/293ec49cc616edac7648784fd469d329/72799/modal-semi90.png 320w", "/static/293ec49cc616edac7648784fd469d329/6af66/modal-semi90.png 640w", "/static/293ec49cc616edac7648784fd469d329/d9199/modal-semi90.png 960w", "/static/293ec49cc616edac7648784fd469d329/21b4d/modal-semi90.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/293ec49cc616edac7648784fd469d329/6af66/modal-semi90.png",
              "alt": "Semi Modal 90%の例",
              "title": "Semi Modal 90%の例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/518919b55152c62e4ca814a92c44f4d1/21b4d/modal-semi90-next.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "126.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/518919b55152c62e4ca814a92c44f4d1/2aaaf/modal-semi90-next.webp 160w", "/static/518919b55152c62e4ca814a92c44f4d1/85e47/modal-semi90-next.webp 320w", "/static/518919b55152c62e4ca814a92c44f4d1/75198/modal-semi90-next.webp 640w", "/static/518919b55152c62e4ca814a92c44f4d1/691bc/modal-semi90-next.webp 960w", "/static/518919b55152c62e4ca814a92c44f4d1/223e5/modal-semi90-next.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/518919b55152c62e4ca814a92c44f4d1/69538/modal-semi90-next.png 160w", "/static/518919b55152c62e4ca814a92c44f4d1/72799/modal-semi90-next.png 320w", "/static/518919b55152c62e4ca814a92c44f4d1/6af66/modal-semi90-next.png 640w", "/static/518919b55152c62e4ca814a92c44f4d1/d9199/modal-semi90-next.png 960w", "/static/518919b55152c62e4ca814a92c44f4d1/21b4d/modal-semi90-next.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/518919b55152c62e4ca814a92c44f4d1/6af66/modal-semi90-next.png",
              "alt": "Semi Modal 90%とSemi Modal 90%の例",
              "title": "Semi Modal 90%とSemi Modal 90%の例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h2 {...{
      "id": "レイアウト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%AC%E3%82%A4%E3%82%A2%E3%82%A6%E3%83%88",
        "aria-label": "レイアウト permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`レイアウト`}</h2>
    <p>{`Modal系で使用するコンポーネントのレイアウトについてのガイドです。
iOSとAndroidの差異についても記述します。`}</p>
    <h3 {...{
      "id": "navigation-header",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#navigation-header",
        "aria-label": "navigation header permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Navigation Header`}</h3>
    <p>{`Modal上部にあたるNavigation Headerのレイアウトです。OS(iOS・Android)によって見出しの位置に差分があります。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8c5f3e13c9fe53e63476725d1e7f3558/21b4d/modal-layout-navigation-header.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8c5f3e13c9fe53e63476725d1e7f3558/2aaaf/modal-layout-navigation-header.webp 160w", "/static/8c5f3e13c9fe53e63476725d1e7f3558/85e47/modal-layout-navigation-header.webp 320w", "/static/8c5f3e13c9fe53e63476725d1e7f3558/75198/modal-layout-navigation-header.webp 640w", "/static/8c5f3e13c9fe53e63476725d1e7f3558/691bc/modal-layout-navigation-header.webp 960w", "/static/8c5f3e13c9fe53e63476725d1e7f3558/223e5/modal-layout-navigation-header.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8c5f3e13c9fe53e63476725d1e7f3558/69538/modal-layout-navigation-header.png 160w", "/static/8c5f3e13c9fe53e63476725d1e7f3558/72799/modal-layout-navigation-header.png 320w", "/static/8c5f3e13c9fe53e63476725d1e7f3558/6af66/modal-layout-navigation-header.png 640w", "/static/8c5f3e13c9fe53e63476725d1e7f3558/d9199/modal-layout-navigation-header.png 960w", "/static/8c5f3e13c9fe53e63476725d1e7f3558/21b4d/modal-layout-navigation-header.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8c5f3e13c9fe53e63476725d1e7f3558/6af66/modal-layout-navigation-header.png",
              "alt": "Full ModalとSemi Modal、iOSとAndroidそれぞれのヘッダーのレイアウト一覧",
              "title": "Full ModalとSemi Modal、iOSとAndroidそれぞれのヘッダーのレイアウト一覧",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "overlay",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#overlay",
        "aria-label": "overlay permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Overlay`}</h3>
    <p>{`Modalの位置によって、BackgroundのOverlayの濃さを調整します。これは、Backgroundタスクのコンテキストの継承レベルを視覚的に表すためです。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c0950c0446e19f91e7b532f0fa838b46/21b4d/modal-layout-overlay.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c0950c0446e19f91e7b532f0fa838b46/2aaaf/modal-layout-overlay.webp 160w", "/static/c0950c0446e19f91e7b532f0fa838b46/85e47/modal-layout-overlay.webp 320w", "/static/c0950c0446e19f91e7b532f0fa838b46/75198/modal-layout-overlay.webp 640w", "/static/c0950c0446e19f91e7b532f0fa838b46/691bc/modal-layout-overlay.webp 960w", "/static/c0950c0446e19f91e7b532f0fa838b46/223e5/modal-layout-overlay.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c0950c0446e19f91e7b532f0fa838b46/69538/modal-layout-overlay.png 160w", "/static/c0950c0446e19f91e7b532f0fa838b46/72799/modal-layout-overlay.png 320w", "/static/c0950c0446e19f91e7b532f0fa838b46/6af66/modal-layout-overlay.png 640w", "/static/c0950c0446e19f91e7b532f0fa838b46/d9199/modal-layout-overlay.png 960w", "/static/c0950c0446e19f91e7b532f0fa838b46/21b4d/modal-layout-overlay.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c0950c0446e19f91e7b532f0fa838b46/6af66/modal-layout-overlay.png",
              "alt": "モーダルのパターンごとのOverlayの色定義",
              "title": "モーダルのパターンごとのOverlayの色定義",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "bottom",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bottom",
        "aria-label": "bottom permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Bottom`}</h3>
    <p>{`iPhoneX以降の端末は、画面下部にノッチとHome Indicatorがあり、基本的にそこに要素が干渉しないようにします。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/75b044a33ee34157a3e4761fc817a8b1/21b4d/modal-layout-bottom.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/75b044a33ee34157a3e4761fc817a8b1/2aaaf/modal-layout-bottom.webp 160w", "/static/75b044a33ee34157a3e4761fc817a8b1/85e47/modal-layout-bottom.webp 320w", "/static/75b044a33ee34157a3e4761fc817a8b1/75198/modal-layout-bottom.webp 640w", "/static/75b044a33ee34157a3e4761fc817a8b1/691bc/modal-layout-bottom.webp 960w", "/static/75b044a33ee34157a3e4761fc817a8b1/223e5/modal-layout-bottom.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/75b044a33ee34157a3e4761fc817a8b1/69538/modal-layout-bottom.png 160w", "/static/75b044a33ee34157a3e4761fc817a8b1/72799/modal-layout-bottom.png 320w", "/static/75b044a33ee34157a3e4761fc817a8b1/6af66/modal-layout-bottom.png 640w", "/static/75b044a33ee34157a3e4761fc817a8b1/d9199/modal-layout-bottom.png 960w", "/static/75b044a33ee34157a3e4761fc817a8b1/21b4d/modal-layout-bottom.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/75b044a33ee34157a3e4761fc817a8b1/6af66/modal-layout-bottom.png",
              "alt": "Modalの長さと、iOSとAndroidそれぞれでのレイアウト例",
              "title": "Modalの長さと、iOSとAndroidそれぞれでのレイアウト例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "shape",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#shape",
        "aria-label": "shape permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Shape`}</h3>
    <p>{`Semi ModalのSheetの角丸を定義しています。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6f3f8608259996bf6bd788b09631824d/21b4d/modal-layout-shape.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.625%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6f3f8608259996bf6bd788b09631824d/2aaaf/modal-layout-shape.webp 160w", "/static/6f3f8608259996bf6bd788b09631824d/85e47/modal-layout-shape.webp 320w", "/static/6f3f8608259996bf6bd788b09631824d/75198/modal-layout-shape.webp 640w", "/static/6f3f8608259996bf6bd788b09631824d/691bc/modal-layout-shape.webp 960w", "/static/6f3f8608259996bf6bd788b09631824d/223e5/modal-layout-shape.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6f3f8608259996bf6bd788b09631824d/69538/modal-layout-shape.png 160w", "/static/6f3f8608259996bf6bd788b09631824d/72799/modal-layout-shape.png 320w", "/static/6f3f8608259996bf6bd788b09631824d/6af66/modal-layout-shape.png 640w", "/static/6f3f8608259996bf6bd788b09631824d/d9199/modal-layout-shape.png 960w", "/static/6f3f8608259996bf6bd788b09631824d/21b4d/modal-layout-shape.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6f3f8608259996bf6bd788b09631824d/6af66/modal-layout-shape.png",
              "alt": "Semi ModalのiOSとAndroidそれぞれでの角丸のサイズ",
              "title": "Semi ModalのiOSとAndroidそれぞれでの角丸のサイズ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "animation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#animation",
        "aria-label": "animation permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Animation`}</h3>
    <p>{`Semi Modalの出現Animationについて定義しています。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7558cd4909cddb3da704726d67e41429/21b4d/modal-layout-animation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.625%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7558cd4909cddb3da704726d67e41429/2aaaf/modal-layout-animation.webp 160w", "/static/7558cd4909cddb3da704726d67e41429/85e47/modal-layout-animation.webp 320w", "/static/7558cd4909cddb3da704726d67e41429/75198/modal-layout-animation.webp 640w", "/static/7558cd4909cddb3da704726d67e41429/691bc/modal-layout-animation.webp 960w", "/static/7558cd4909cddb3da704726d67e41429/223e5/modal-layout-animation.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7558cd4909cddb3da704726d67e41429/69538/modal-layout-animation.png 160w", "/static/7558cd4909cddb3da704726d67e41429/72799/modal-layout-animation.png 320w", "/static/7558cd4909cddb3da704726d67e41429/6af66/modal-layout-animation.png 640w", "/static/7558cd4909cddb3da704726d67e41429/d9199/modal-layout-animation.png 960w", "/static/7558cd4909cddb3da704726d67e41429/21b4d/modal-layout-animation.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7558cd4909cddb3da704726d67e41429/6af66/modal-layout-animation.png",
              "alt": "Semi Modalのアニメーションの例",
              "title": "Semi Modalのアニメーションの例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "scroll",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#scroll",
        "aria-label": "scroll permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Scroll`}</h3>
    <p>{`見出しに対して遷移を行えるだけの一番シンプルなリストの例です。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/47d4443a069477ab33462c150cd840af/21b4d/modal-layout-scroll.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/47d4443a069477ab33462c150cd840af/2aaaf/modal-layout-scroll.webp 160w", "/static/47d4443a069477ab33462c150cd840af/85e47/modal-layout-scroll.webp 320w", "/static/47d4443a069477ab33462c150cd840af/75198/modal-layout-scroll.webp 640w", "/static/47d4443a069477ab33462c150cd840af/691bc/modal-layout-scroll.webp 960w", "/static/47d4443a069477ab33462c150cd840af/223e5/modal-layout-scroll.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/47d4443a069477ab33462c150cd840af/69538/modal-layout-scroll.png 160w", "/static/47d4443a069477ab33462c150cd840af/72799/modal-layout-scroll.png 320w", "/static/47d4443a069477ab33462c150cd840af/6af66/modal-layout-scroll.png 640w", "/static/47d4443a069477ab33462c150cd840af/d9199/modal-layout-scroll.png 960w", "/static/47d4443a069477ab33462c150cd840af/21b4d/modal-layout-scroll.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/47d4443a069477ab33462c150cd840af/6af66/modal-layout-scroll.png",
              "alt": "見出しに対して遷移を行えるだけの一番シンプルなリストの例",
              "title": "見出しに対して遷移を行えるだけの一番シンプルなリストの例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h2 {...{
      "id": "アクセシビリティ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3",
        "aria-label": "アクセシビリティ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`アクセシビリティ`}</h2>
    <p>{`現在作成中です。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      